import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { ActivateAdminUserRequestDto } from "../../common/dtos/activate-admin-user-request.dto";
import { ProxyService } from "../../common/proxy/proxy.service";

@Component({
  selector: "app-activate-admin-user",
  templateUrl: "./activate-admin-user.component.html",
  styleUrls: ["./activate-admin-user.component.scss"],
})
export class ActivateAdminUserComponent implements OnInit {
  title = "ACTIVA TU CUENTA";
  subtitle = "Para activar tu cuenta, tienes que crear una nueva contraseña";
  form = new FormGroup({});
  formUser: ActivateAdminUserRequestDto;

  fields: FormlyFieldConfig[] = [
    {
      validators: {
        validation: [
          { name: "fieldMatch", options: { errorPath: "passwordConfirm" } },
        ],
      },
      fieldGroup: [
        {
          key: "password",
          type: "input",
          templateOptions: {
            type: "password",
            label: "Contraseña",
            placeholder: "",
            required: true,
            minLength: 8,
          },
        },
        {
          key: "passwordConfirm",
          type: "input",
          templateOptions: {
            type: "password",
            label: "Confirmar contraseña",
            placeholder: "",
            required: true,
          },
        },
      ],
    },
  ];

  markError = false;
  errorMessage = "";
  done = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private proxy: ProxyService
  ) {}

  ngOnInit() {
    this.validateToken();
  }

  submit() {
    console.log("form -> ", this.form);
    if (this.form.valid) {
      console.log(this.formUser);
      const dto = {
        tokenId: this.formUser.tokenId,
        password: this.formUser.password,
      };
      this.proxy.validateUserAccount(dto).subscribe(
        (result) => {
          this.redirectDone();
        },
        (error) => {
          this.errorMessage =
            "No se ha podido activar su cuenta, por favor contacta con soporte@icomunity.com";
          this.markError = true;
        }
      );
    }
  }

  validateToken() {
    const token = this.route.snapshot.queryParams["token"];
    console.log("token -> ", token);
    if (token) {
      this.proxy.validateToken(token).subscribe(
        (result) => {
          console.log("result -> ", result);
          if (result) {
            this.initForm(result);
          } else {
            this.redirect403();
          }
        },
        (error) => {
          this.redirect403();
        }
      );
    } else {
      this.redirect403();
    }
  }

  initForm(apiResult) {
    this.formUser = {
      tokenId: apiResult.tokenId,
      email: apiResult.email,
      name: apiResult.name,
      password: null,
    };
  }

  redirect403() {
    this.router.navigateByUrl("403");
  }

  redirectDone() {
    this.done = true;
  }
}
