// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLang: "es",
  version: "2022.08.16",
  soporteEmail: "soporte.icomunity@gmail.com",
  // migrationApiUrl: "http://localhost:4080/api/v1",
  migrationApiUrl: "https://backbone.berekstan.com:4081/api/v1",
  //migrationApiUrlV2: "http://localhost:4080/api/v2",
  migrationApiUrlV2: "https://backbone.berekstan.com:4081/api/v2",
};
