import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActivateAdminUserComponent } from "./views/activate-admin-user/activate-admin-user.component";
import { ResetPasswordAdminUserComponent } from "./views/reset-password-admin-user/reset-password-admin-user.component";
import { HttpClientModule } from "@angular/common/http";
import { AbstractControl, ReactiveFormsModule } from "@angular/forms";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { HeaderComponent } from "./common/components/header/header.component";
import { FooterComponent } from "./common/components/footer/footer.component";
import { NotPermitComponent } from "./views/not-permit/not-permit.component";
import { ProxyService } from "./common/proxy/proxy.service";
import { DoneComponent } from "./views/done/done.component";

export function minlengthValidationMessages(err, field) {
  return `Mínimo ${field.templateOptions.minLength} caracteres`;
}

export function fieldMatchValidator(control: AbstractControl) {
  const { password, passwordConfirm } = control.value;

  // avoid displaying the message error when values are empty
  if (!passwordConfirm || !password) {
    return null;
  }

  if (passwordConfirm === password) {
    return null;
  }

  return { fieldMatch: { message: "Las contraseñas no coinciden" } };
}

@NgModule({
  declarations: [
    AppComponent,
    ActivateAdminUserComponent,
    ResetPasswordAdminUserComponent,
    HeaderComponent,
    FooterComponent,
    NotPermitComponent,
    DoneComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot({
      validators: [{ name: "fieldMatch", validation: fieldMatchValidator }],
      validationMessages: [
        { name: "required", message: "Campo requerido" },
        { name: "minlength", message: minlengthValidationMessages },
      ],
    }),
  ],
  providers: [ProxyService],
  bootstrap: [AppComponent],
})
export class AppModule {}
