import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) {}
  canActivate(route: ActivatedRouteSnapshot) {
    console.log("guard -> ", route);
    if (route.queryParams["token"]) {
      return true;
    }
    this._router.navigateByUrl("403");
    return false;
  }
}
