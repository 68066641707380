import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class ProxyService {
  constructor(private http: HttpClient) {}

  resetPassword(dto: any): Observable<any> {
    return this.doPostRequest(
      `${environment.migrationApiUrlV2}/admin-user/reset-password`,
      dto
    );
  }

  validateUserAccount(dto: any): Observable<any> {
    return this.doPostRequest(
      `${environment.migrationApiUrlV2}/admin-user/validate`,
      dto
    );
  }

  validateToken(token: string): Observable<any> {
    return this.doPostRequest(
      `${environment.migrationApiUrlV2}/admin-user/validate-token`,
      { token }
    );
  }

  doGetRequest(url: string, params: any): Observable<any> {
    return this.http.get<any>(url, params).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  doPostRequest(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
