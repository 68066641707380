import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./common/guards/auth.guard";
import { ActivateAdminUserComponent } from "./views/activate-admin-user/activate-admin-user.component";
import { DoneComponent } from "./views/done/done.component";
import { NotPermitComponent } from "./views/not-permit/not-permit.component";
import { ResetPasswordAdminUserComponent } from "./views/reset-password-admin-user/reset-password-admin-user.component";

const routes: Routes = [
  {
    path: "activate",
    component: ActivateAdminUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reset-password",
    component: ResetPasswordAdminUserComponent,
    canActivate: [AuthGuard],
  },
  { path: "done", component: DoneComponent },
  { path: "403", component: NotPermitComponent },
  { path: "", redirectTo: "/403", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
