import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-not-permit",
  templateUrl: "./not-permit.component.html",
  styleUrls: ["./not-permit.component.scss"],
})
export class NotPermitComponent implements OnInit {
  title = "ACCESO NO PERMITIDO";
  icon = "assets/icons/403.png";
  constructor() {}

  ngOnInit() {}
}
